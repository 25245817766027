@import 'src/ui-kit/styles/colors';

.content-block__textarea-field {
  &__control {
    box-sizing: border-box;
    padding: 12px 18px;
    line-height: 1.2;
    height: 44px;
    border: 1px solid $elo-grey-40;
    border-radius: 4px;
    background-color: $elo-white;

    &--height-90 {
      height: 90px;
    }

    &--height-120 {
      height: 120px;
    }

    &:focus:not(&--error) {
      border: 1px solid $elo-blue;
    }

    &--unset-height {
      height: unset;
    }

    &--error,
    &:invalid {
      border: 1px solid $elo-red;
    }

    // Override Bootstrap styles
    &::placeholder {
      color: $elo-grey-40 !important;
      font-size: 14px !important;
      line-height: 0 !important;
    }
    &::-webkit-input-placeholder {
      line-height: 0 !important;
    }

    &--without-resize {
      resize: none;
    }
  }
}
