.elo-product-display {
  --elo-product-display-name-font: var(--elo-body-sm-medium);
  --elo-product-display-name-color: var(--color-primary-dg-brand);
  --elo-product-display-internal-name-font: var(--elo-body-sm-regular);
  --elo-product-display-internal-name-color: var(--color-primary-dg-400);
  --elo-product-display-cover-background-color: var(--color-primary-dg-200);
  --elo-product-display-cover-product-type-icon-background-color: var(--color-primary-dg-100);
  --elo-product-display-cover-product-type-icon-color: var(--color-primary-dg-500);

  width: 100%;
  min-width: 150px;
  display: flex;
  align-items: center;
  gap: 24px;

  &--size-xs {
    max-width: 200px;
  }

  &--size-md {
    max-width: 500px;
  }

  &--size-extra-large {
    justify-content: center;
    gap: 32px;
    padding: 64px 0;

    .elo-product-display__description {
      gap: 16px;
    }
  }

  &__image {
    position: relative;

    img {
      object-fit: cover;
    }
  }

  &__type-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: -4px;
    right: -12px;

    &--small {
      width: 24px;
      height: 24px;
    }

    .elo-tooltip__content {
      text-transform: capitalize;
    }
  }

  &__name {
    font: var(--elo-product-display-name-font);
    color: var(--elo-product-display-name-color);
    align-items: center;
  }

  &__prices {
    display: flex;
    column-gap: 8px;
    flex-wrap: wrap;
  }

  &__internal-name {
    font: var(--elo-product-display-internal-name-font);
    color: var(--elo-product-display-internal-name-color);
    text-decoration: none;
  }

  &__internal-name,
  &__name {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-all;
    max-width: 100%;
  }

  &__description {
    display: flex;
    flex-direction: column;
    max-width: calc(100% - 56px);
  }

  &__link {
    display: flex;
    align-items: center;
    gap: 24px;
    max-width: 100%;
    height: 100%;

    .elo-product-display__name {
      text-decoration: underline;
    }
  }
}
