$elo-green: #39aa75;
$elo-white: #ffffff;
$elo-white-100: #fafafa;
$elo-black: #4a4a4a;
$elo-red: #ff2800;
$elo-red-50: #e80c0c;
$elo-red-100: #a60000;
$elo-orange: #ffb000;
$elo-orange-10: #ffd66f;
$elo-orange-20: #ffce61;
$elo-orange-30: #ffc543;
$elo-orange-40: #ffbc26;
$elo-orange-50: #e89300;
$elo-orange-60: #f39103;
$elo-orange-70: #ff9100;
$elo-orange-80: #d57f01;
$elo-orange-90: #ff9a00;
$elo-orange-100: #ff7f00;
$elo-yellow: #ffdf71;
$elo-blue: #1e84d7;
$elo-blue-50: #1177d5;
$elo-blue-100: #0561b4;
$elo-grey-10: #f2f1f1;
$elo-grey-15: #fcfcfc;
$elo-grey-20: #efedef;
$elo-grey-25: #dcdcdc;
$elo-grey-30: #ececec;
$elo-grey-35: #dedede;
$elo-grey-40: #cdcdcd;
$elo-grey-45: #d7d7d7;
$elo-grey-50: #b7b7b7;
$elo-grey-60: #9e9e9e;
$elo-grey-70: #787878;
$elo-grey-80: #717171;
$elo-grey-90: #4a4a4a;
$builder-grey-10: #f2f2f2;
$builder-grey-20: #e4e4e4;
$builder-grey-30: #dddddd;
$builder-grey-40: #9b9b9b;