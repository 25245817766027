.elo-user-display {
  --elo-user-display-name-font: var(--elo-body-sm-medium);
  --elo-user-display-description-font: var(--elo-body-sm-regular);
  --elo-user-display-name-color: var(--color-primary-dg-brand);
  --elo-user-description-name-color: var(--color-primary-dg-400);

  display: flex;
  align-items: center;
  justify-content: flex-start;

  &__block {
    margin-left: 12px;
    font-family: 'Inter';
    font-style: normal;
    font-size: 14px;
    line-height: 22px;
    max-width: 200px;
    overflow: visible;
  }

  &__username {
    color: var(--elo-user-display-name-color);
    font: var(--elo-user-display-name-font);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__description {
    color: var(--elo-user-description-name-color);
    font: var(--elo-user-display-description-font);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__tooltip-wrapper {
    display: flex;
    flex-direction: row;
    max-width: 100%;
  }

  &--clickable {
    cursor: pointer;

    .elo-user-display__username {
      text-decoration: underline;
    }
  }
}
