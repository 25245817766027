.ticket-availability-error-modal {
  z-index: 1061;

  .elo-ui-modal__dialog {
    align-content: center;
    margin: auto;
  }

  .ticket-availability-modal__title {
    display: flex;
    align-items: center;
    gap: 16px;

    .ticket-availability-modal__icon {
      svg {
        color: var(--elo-utility-error-dark) !important;
      }
    }
  }

  &__body {
    font: var(--elo-body-sm-regular);
    color: var(--elo-primary-dg-brand);
    margin: 0;

    ul {
      padding-left: 23px;
    }
  }
}
