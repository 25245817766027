@import 'src/ui-kit/styles/colors';
@import 'src/ui-kit/styles/page-builder';

.content-block__text-field {
  &__control {
    box-sizing: border-box;
    padding: 12px 18px;
    height: 44px;
    line-height: 13px;
    border: 1px solid $elo-grey-40;
    border-radius: 4px;
    background-color: $elo-white;

    &:focus:not(&--error) {
      border: 1px solid $elo-blue;
    }

    &--lowercased {
      text-transform: lowercase;
    }

    &--error,
    &:invalid {
      border: 1px solid $elo-red;
    }

    &-wrapper {
      position: relative;
      width: 100%;
      display: flex;

      .password-icon {
        display: inline;
        margin-left: -30px;
        cursor: pointer;
        align-self: center;
        color: $elo-grey-40;

        &:hover {
          color: $elo-grey-dark;
        }
      }
    }

    // Override Bootstrap styles
    &::placeholder {
      color: $elo-grey-40 !important;
      font-size: 14px !important;
      padding-top: 0 !important;
    }

    &--dark-value::placeholder {
      color: $elo-black-semi !important;
    }

    // specific selector only for Chrome 29+
    @media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
      //font-size: revert;
      font-size: 14px;
    }
  }

  &--disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &--required {
    .content-block-field__label,
    .checkbox-field__label {
      span:first-child::after {
        content: '*';
        margin: -1px 2px;
        font-size: 15px;
        color: var(--field-required-font-color);
      }
    }
  }

  &__additional-text {
    font-family: $builder-font-medium;
    font-size: 13px;
    color: $elo-grey;
  }
}
