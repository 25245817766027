$elo-white: #ffffff;
$elo-black: #000000;
$elo-black-semi: #4a4a4a;
$elo-grey-dark: #787878;
$elo-grey-medium: #495057;
$elo-grey: #9e9e9e;
$elo-grey-30: #ececec;
$elo-grey-40: #cdcdcd;
$elo-grey-light-60: #e5e2e5;
$elo-grey-light: #eaeaea;
$elo-grey-light-50: #ced4da;
$elo-grey-light-40: #efedef;
$elo-grey-light-30: #f2f1f2;
$elo-grey-light-20: #f4f3f4;
$elo-grey-light-10: #fafafa;
$elo-red-60: #dd2121;
$elo-red: #ff4747;
$elo-red-40: #ff6868;
$elo-red-shadow: #dc354540;
$elo-orange-70: #ff7104;
$elo-orange-60: #ff9604;
$elo-orange: #ffb000;
$elo-green: #39aa75;
$elo-coral: #17bea3;
$elo-blue-dark: #013c73;
$elo-blue-70: #0561b4;
$elo-blue-60: #1177d5;
$elo-blue: #1e84d7;
$elo-blue-light: #6ea7dc;
$elo-blue-light-40: #9ec5d8;
$elo-blue-light-30: #c8dee9;
$elo-blue-shadow: #007bff40;