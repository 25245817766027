.story-icon-preview {
  $primary-700: #8f9295;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  font-family: sans-serif;
  padding: 16px;

  .name {
    font-size: 14px;
    color: $primary-700;
  }
}
