.upgrade-tickets-modal {
  .elo-ui-modal__body {
    padding: 2px;
  }

  .elo-ui-modal__content {
    padding: 56px 88px;
    justify-content: space-between;

    .elo-ui-modal__body {
      .elo-card-ticket-wrapper {
        scroll-margin-top: 15px;

        .elo-card__selectable {
          background-color: var(--elo-primary-cg-100);
        }
      }

      @media (max-width: 576px) {
        max-height: 100vh;
      }

      .upgrade-modal-info-message {
        margin-bottom: 24px;
      }
    }

    .elo-ui-modal__header {
      .elo-info-message {
        margin-top: 15px;
      }
    }

    .elo-ui-modal__footer {
      flex-direction: column;
      gap: 16px;

      .terms {
        display: flex;
        flex-direction: column;

        .seller-terms {
          order: 2;
        }

        .cancel-terms__text,
        .seller-terms {
          font: var(--elo-medium);
          color: var(--elo-primary-dg-brand);
        }
      }

      .summary {
        width: 30%;

        &-container {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;

          @media (max-width: 576px) {
            flex-direction: column;
            gap: 16px;
            align-items: normal;
          }

          .action-buttons {
            align-items: flex-end;
            margin-left: auto;

            @media (max-width: 576px) {
              margin: 0;
            }

            .buttons-container {
              display: flex;
              gap: 16px;
              align-items: flex-end;
              margin-left: auto;
              justify-content: end;

              @media (max-width: 576px) {
                justify-content: space-between;
                margin-left: 0;

                .elo-button {
                  width: 100%;
                }
              }
            }

            .text-container {
              margin-top: 8px;
              font: var(--elo-body-xs-regular);
              color: var(--elo-primary-dg-400);
              text-align: justify;
              margin-left: 16px;

              @media (max-width: 576px) {
                margin-left: 0;
              }
            }
          }
        }

        @media (max-width: 576px) {
          width: 100%;
        }

        .container-title {
          display: none;
        }

        .subtotal {
          display: none;
        }

        .prices-item {
          display: flex;
          flex-direction: column;

          > *:last-child {
            font: var(--elo-body-sm-medium);
          }

          > * {
            font: var(--elo-body-sm-regular);

            + * {
              margin-top: 16px;
            }
          }
        }

        .total {
          color: var(--color-primary-dg-brand);
          margin: 0;

          > * {
            padding: 0;
          }

          div:first-child {
            font: var(--elo-heading-h5);
          }

          div:last-child {
            font: var(--elo-heading-h5);
          }
        }

        .vat-preview {
          display: flex;
          justify-content: space-between;

          span:first-child {
            color: var(--elo-primary-dg-400);
            font: var(--elo-body-sm-regular);
          }

          span:last-child {
            color: var(--elo-primary-dg-brand);
            font: var(--elo-body-sm-regular);
          }
        }

        .vat-included {
          display: none;
        }
      }
    }

    padding: 56px 88px;

    @media (max-width: 576px) {
      padding: 32px;
      justify-content: space-between;
    }

    .elo-ui-modal__footer {
      &--center {
        .elo-button {
          width: 100%;
        }
      }

      .terms {
        .seller-terms {
          font: var(--elo-medium);
        }
      }
    }

    .tooltip-popup-section {
      display: flex;
      position: fixed;
      border-radius: 32px 32px 8px 8px;
      bottom: 0;
      flex-direction: column;
      left: 0;
      padding: 16px;
      width: 100%;
      border: 1px;
      background-color: white;
      border: 0.2px solid var(--elo-primary-dg-200);
      gap: 10px;

      &__price-block {
        display: flex;
        justify-content: space-between;
      }

      &__tooltip-price {
        color: var(--elo-neutral-black);
        font: var(--elo-heading-h5);
      }

      &__tooltip-plan {
        color: var(--elo-primary-dg-500);
        font: var(--elo-body-xs-regular);
        display: flex;
        flex-direction: column-reverse;
      }

      &__tooltip-message {
        color: var(--elo-neutral-black);
        font: var(--elo-body-xs-regular);
      }

      &__tooltip-combined-price {
        display: flex;
        gap: 8px;
      }
    }
  }

  .ticket {
    scroll-margin-top: 100px;
    margin-bottom: 24px;
    padding: 16px;
    box-shadow:
      0px 1px 2px 0px rgba(0, 0, 0, 0.1),
      0px 2px 2px 1px rgba(0, 0, 0, 0.1);
    border-radius: 0px;

    &.elo-card__disabled {
      .elo-card__content {
        pointer-events: none;
      }
    }

    .elo-card__content {
      margin-bottom: 0;
    }

    &-label {
      display: flex;
      align-items: center;
      gap: 8px;
      overflow: hidden;

      &__description {
        display: flex;
        gap: 8px;
        align-items: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        min-width: fit-content;

        @media (max-width: 576px) {
          text-overflow: unset;
          max-width: 50%;
          width: fit-content;
        }

        &-name {
          font: var(--elo-body-sm-medium);
          color: var(--elo-neutral-black);
        }

        &-id {
          font: var(--elo-body-sm-regular);
          color: var(--elo-primary-dg-400);
        }

        &-info {
          font: var(--elo-body-sm-regular);
          color: var(--elo-primary-dg-600);
        }
      }

      &__event-type {
        font: var(--elo-body-sm-regular);
        color: var(--elo-primary-dg-400);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      svg {
        min-width: 16px;
      }

      @media (max-width: 576px) {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &-highlighted-text {
      font: var(--elo-body-sm-regular);
      color: var(--elo-primary-dg-400);
    }

    &__delimiter {
      display: flex;
      justify-content: space-between;
      margin: 16px 0;
      align-items: center;
    }

    &__header {
      &-title {
        display: flex;
        align-items: center;
        gap: 16px;

        @media (max-width: 576px) {
          align-items: flex-start;
        }

        .attendee-info {
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: space-between;

          @media (max-width: 576px) {
            gap: 16px;
            align-items: flex-start;
          }

          &__title {
            .upgrade-available-copy {
              color: #05ab5b;
            }

            .upgrade-selected-copy {
              color: var(--elo-primary-dg-brand);
            }

            .upgrade-sold_out-copy {
              color: var(--elo-primary-dg-300);
            }

            .upgrade-unavailable-copy {
              color: var(--elo-primary-dg-brand);
            }

            font: var(--elo-body-sm-medium);
          }
        }

        .expand-button {
          background-color: transparent;
          border-color: transparent;
        }
      }

      &-description {
        display: flex;
        align-items: center;
        gap: 16px;

        &-date,
        &-info {
          color: var(--elo-primary-dg-400);
        }
      }
    }

    .upgrade__tooltip-container:hover::after {
      content: attr(data-fulltext);
      position: absolute;
      left: 15px;
      top: 13%;
      height: max-content;
      background-color: #333;
      color: #fff;
      padding: 5px;
      border-radius: 5px;
      z-index: 1;
      margin-top: 5px;
      white-space: normal;
    }

    &__upgrade {
      &-block {
        &__ticket {
          display: flex;
          flex-direction: column;
          gap: 8px;
          margin-top: 16px;

          &-name {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font: var(--elo-body-sm-medium);

            @media (max-width: 576px) {
              text-overflow: unset;
              white-space: normal;
            }
          }
        }

        &__tickets-container {
          display: flex;
          flex-direction: column;
          gap: 16px;
        }

        &__date {
          display: flex;
          align-items: center;
          gap: 8px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          &-time {
            color: var(--elo-primary-dg-400);
          }

          @media (max-width: 576px) {
            text-overflow: unset;
            white-space: normal;
            align-items: start;

            svg {
              margin-top: 2px;
            }
          }

          svg {
            min-width: 16px;
          }

          &-description {
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        &__location {
          display: flex;
          align-items: center;
          gap: 8px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          @media (max-width: 576px) {
            text-overflow: unset;
            white-space: normal;
            align-items: start;
            padding-top: 2px;
          }

          span {
            color: var(--elo-primary-dg-400);
            font: var(--elo-body-sm-regular);
            overflow: hidden;
            text-overflow: ellipsis;
          }

          svg {
            min-width: 16px;
            transform: scale(1.3);
          }
        }
      }

      &-cards {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;

        .ticket__upgrade-card {
          flex: 1 1 calc(49% - 10px);

          &.elo-card__disabled {
            .elo-card__content {
              pointer-events: none;
            }
          }

          &__title {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            @media (max-width: 576px) {
              text-overflow: unset;
              white-space: normal;
            }
          }

          &__location {
            display: flex;
            gap: 8px;
            align-items: center;

            &-text {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              flex: 1;

              @media (max-width: 576px) {
                text-overflow: unset;
                white-space: normal;
              }
            }
          }

          &__input-field {
            .disabled {
              background-color: var(--elo-primary-dg-100);

              .elo-react-select__single-value--is-disabled {
                color: var(--elo-neutral-black);
              }
            }

            .elo-react-select {
              &__value-container {
                .elo-react-select__option--wrapper {
                  display: flex;
                  gap: 4px;
                  width: 100%;

                  .elo-react-select__option--title {
                    max-width: 67%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }

                  .elo-react-select__option--subtitle {
                    font: var(--elo-body-sm-regular);
                    color: var(--elo-primary-dg-400);
                  }
                }
              }

              &__single-value {
                width: 100%;
              }
            }

            .elo-react-select__option {
              justify-content: flex-start;
              gap: 8px;

              span {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              &--is-disabled {
                background-color: var(--react-select-option-background-color) !important;

                .elo-react-select__option--wrapper {
                  .elo-react-select__option--title,
                  .elo-react-select__option--subtitle {
                    color: var(--elo-primary-dg-300) !important;
                  }
                }
              }

              .elo-react-select__option--wrapper {
                display: flex;
                gap: 4px;
                width: 100%;

                .elo-react-select__option--title {
                  max-width: 67%;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }

                .elo-react-select__option--subtitle {
                  font: var(--elo-body-sm-regular);
                  color: var(--elo-primary-dg-400);
                }
              }
            }

            .elo-react-select__control--menu-is-open {
              border: 1px solid var(--elo-card-selected-border-color);
            }
          }

          &__price-section {
            display: flex;
            gap: 8px;

            .elo-tooltip__wrapper {
              display: block;
              color: var(--elo-primary-dg-400);
            }
          }

          .elo-card__content {
            display: flex;
            flex-direction: column;
            gap: 14px;

            .elo_price_display {
              margin-left: auto;
            }

            .elo-select {
              margin-bottom: 24px;
            }
          }
        }
      }
    }
  }
}
