@import 'src/ui-kit/styles/colors';

.content-block__date-time-field {
  &__control {
    padding: 15px 18px 12px;
  }

  &__control-wrapper {
    position: relative;
    width: 100%;

    .content-block__date-time-field {
      box-sizing: border-box;
      padding: 15px 18px 12px;
      height: 44px;
      border: 1px solid $elo-grey-40;
      border-radius: 4px;
      background-color: $elo-white;
      line-height: 13px;

      // specific selector only for Chrome 29+
      @media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
        line-height: 44px;
        //font-size: revert;
        font-size: 14px;
      }
    }

    .content-block__date-time-field__control:focus:not(&--error) {
      border: 1px solid $elo-blue;
    }

    .content-block__date-time-field__control--error {
      border: 1px solid $elo-red;
    }

    // Override Bootstrap styles
    .content-block__date-time-field__control::placeholder {
      color: $elo-grey-40 !important;
      font-size: 14px !important;
      padding-top: 1px !important;
    }

    .fa-calendar-alt {
      position: absolute;
      top: 13px;
      right: 15px;
      font-size: 17px;
      color: $elo-blue;
    }
  }

  .rdtPicker {
    color: $elo-black-semi;

    table {
      td {
        border: none;
      }
      th {
        background: $elo-white;
        border: none;
      }
    }

    th {
      border-bottom: none;
      text-transform: uppercase;
    }

    th.rdtPrev {
      font-size: 25px;
      color: $elo-blue;
    }

    th.rdtNext {
      font-size: 25px;
      color: $elo-blue;
    }

    th.rdtSwitch {
      padding-top: 4px;
      font-family: var(--elo-font-sans-serif);
      font-weight: 500;
      font-size: 12px;
    }

    th.dow {
      font-family: var(--elo-font-sans-serif);
      font-weight: 500;
      font-size: 10px;
    }

    td.rdtDay {
      font-size: 12px;

      &:hover {
        border-radius: 20px;
      }
    }

    td.rdtActive {
      background-color: $elo-blue;
      text-shadow: none;
      border-radius: 20px;
    }
  }

  &--rdt-100 {
    .rdtPicker {
      width: 100%;
    }
  }
}
