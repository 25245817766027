.elo-avatar {
  --elo-avatar-background-color: var(--color-primary-dg-200);
  --elo-avatar-color: var(--color-primary-dg-brand);

  background-color: var(--elo-avatar-background-color);
  color: var(--elo-avatar-color);
  display: block;
  box-sizing: border-box;
  overflow: hidden;

  &__small {
    width: 20px;
    height: 20px;
    border-radius: 2px;
  }

  &__medium {
    width: 24px;
    height: 24px;
    border-radius: 3px;
  }

  &__large {
    width: 32px;
    height: 32px;
    border-radius: 4px;
  }

  &__extra-large {
    width: 100px;
    height: 100px;
    border-radius: 4px;
    font-size: 40px;
  }

  &__extra-extra-large {
    width: 256px;
    height: 256px;
    border-radius: 16px;
    font-size: 40px;
  }

  &__fullwidth {
    width: 100%;
    height: auto;
  }

  &__default {
    border-radius: 0;
  }

  &__circle {
    border-radius: 256px;
  }

  &__square {
    display: inline-flex;
    position: relative;
  }

  &__placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
  }
}
