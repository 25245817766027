.story-cell {
  $primary-900: #21282e;
  $primary-700: #8f9295;
  $primary-600: #d7dadf;

  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-family: sans-serif;
  color: $primary-900;
  border: 1px solid $primary-600;

  &:nth-child(3n),
  &:nth-child(3n + 2) {
    border-left: none;
  }

  &:nth-child(n + 4) {
    border-top: none;
  }

  .token {
    padding: 4px 8px;
    background-color: $primary-600;
    border-radius: 4px;
  }

  .value {
    color: $primary-700;
    font-size: 12px;
    text-align: center;
  }

  .description {
    margin-right: auto;
  }
}

.story-flex {
  display: flex;
  .story-cell {
    text-align: left;
  }
  .story-cell:first-child {
    width: 30%;
  }
  .story-cell:nth-child(2) {
    width: 70%;
  }
}
