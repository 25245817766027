@import 'src/ui-kit/styles/colors';

.content-block__country-selector-field {
  font-size: 16px;

  &__error {
    .field {
      margin-bottom: 0;
    }

    .content-block__select-field__control {
      border-color: $elo-red-60;

      &:hover {
        border-color: $elo-red-60;
      }
    }
  }

  &--invalid-feedback {
    display: block;
    width: 100%;
    margin-top: 4px;
    font-size: 80%;
    color: $elo-red-60;
  }
}
