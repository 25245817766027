@import '../components/page-builder/components/colors/colors.json';

$builder-field-bottom-margin: 14px;
$builder-field-height: 25px;

$builder-font-light: 'Montserrat Light';
$builder-font-medium: 'Montserrat Reg';
$builder-font-bold: 'Montserrat Medium';

$builder-font-size-xs: 10px;
$builder-font-size-sm: 12px;
$builder-font-size-md: 16px;
$builder-font-size-lg: 18px;

$builder-side-panel-width: 276px;

$elo-green: $elo-green;

$elo-white: $elo-white;
$elo-white-100: $elo-white-100;

$elo-black: $elo-black;

$elo-red: $elo-red;
$elo-red-50: $elo-red-50;
$elo-red-100: $elo-red-100;

$elo-orange: $elo-orange;
$elo-orange-10: $elo-orange-10;
$elo-orange-20: $elo-orange-20;
$elo-orange-30: $elo-orange-30;
$elo-orange-40: $elo-orange-40;
$elo-orange-50: $elo-orange-50;
$elo-orange-60: $elo-orange-60;
$elo-orange-70: $elo-orange-70;
$elo-orange-80: $elo-orange-80;
$elo-orange-90: $elo-orange-90;
$elo-orange-100: $elo-orange-100;

$elo-yellow: $elo-yellow;

$elo-blue: $elo-blue;
$elo-blue-50: $elo-blue-50;
$elo-blue-100: $elo-blue-100;

$elo-grey-10: $elo-grey-10;
$elo-grey-15: $elo-grey-15;
$elo-grey-20: $elo-grey-20;
$elo-grey-25: $elo-grey-25;
$elo-grey-30: $elo-grey-30;
$elo-grey-35: $elo-grey-35;
$elo-grey-40: $elo-grey-40;
$elo-grey-45: $elo-grey-45;
$elo-grey-50: $elo-grey-50;
$elo-grey-60: $elo-grey-60;
$elo-grey-70: $elo-grey-70;
$elo-grey-80: $elo-grey-80;
$elo-grey-90: $elo-grey-90;

$builder-grey-10: $builder-grey-10;
$builder-grey-20: $builder-grey-20;
$builder-grey-30: $builder-grey-30;
$builder-grey-40: $builder-grey-40;
